import React from "react"
import { Box, Flex } from "rebass"
import type { File, StrapiPost } from "types/graphql-types"

import { Columns } from "@components/Columns"
import { Image } from "@components/Image"

import { styled } from "@lib/theme"

const Title = styled.h2`
    display: flex;
    box-sizing: border-box;
    width: 100%;
    vertical-align: middle;
    margin: 0 0 20px;
`

const Description = styled.aside`
    display: flex;
    box-sizing: border-box;
    width: 100%;
    vertical-align: middle;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 20px;
`

const PostHeaderComponent = ({ children }: React.PropsWithChildren<unknown>) => (
    <Flex justifyContent="center">
        <Box flex={["100%", "50%"]} maxWidth={["100%", "66%"]} paddingX={["0px", "20px"]}>
            {children}
        </Box>
    </Flex>
)

const PostComponent = ({ title, showTitle, description, ...props }: StrapiPost) => {
    if (!props.images) {
        return null
    }

    const images = props.images.map((img) => img?.localFile).filter((file): file is File => Boolean(file))
    const firstImage = images.shift()
    const secondImage = images.shift()

    return (
        <Box marginBottom="100px">
            <PostHeaderComponent>
                {showTitle && <Title>{title}</Title>}
                {description && <Description>{description}</Description>}
            </PostHeaderComponent>
            <Columns>
                {firstImage && <Image file={firstImage} alignRight={Boolean(secondImage)} fullSize={!secondImage} />}
                {secondImage && <Image file={secondImage} />}
                {images.map((img, j) => (
                    <Image key={j} file={img} alignRight={j % 2 === 0} />
                ))}
            </Columns>
        </Box>
    )
}

export const Post = React.memo(PostComponent)
