import React from "react"
import { graphql } from "gatsby"
import type { Query, StrapiPostEdge } from "types/graphql-types"

import Layout from "@layouts/Layout"
import { Post } from "@components/Post"
import SEO from "@components/Seo"

const IndexPage = ({ data }: { data: Query }) => {
    const { edges } = data.allStrapiPost
    const posts = edges.filter((edge): edge is StrapiPostEdge => Boolean(edge.node && edge.node.published))

    return (
        <Layout hideNavigation={posts.length === 0}>
            <SEO />
            {posts.map(({ node }) => (
                <Post key={node.id} {...node} />
            ))}
        </Layout>
    )
}

export const pageQuery = graphql`
    query PostsQuery {
        allStrapiPost(sort: { order: DESC, fields: created_at }) {
            edges {
                node {
                    id
                    title
                    description
                    showTitle
                    published
                    images {
                        localFile {
                            childImageSharp {
                                fluid(maxHeight: 700) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                            fields {
                                slug
                            }
                        }
                    }
                }
            }
        }
    }
`

export default IndexPage
