import React from "react"
import { Link as GatsbyLink } from "gatsby"
import GatsbyImg from "gatsby-image"
import { Box } from "rebass"
import { File } from "types/graphql-types"

import { styled } from "@lib/theme"

import IconZoom from "../assets/icon-zoom-in.svg"

const Img = styled<typeof GatsbyImg, Omit<IImageProps, "file">>(GatsbyImg)`
    display: block;
    max-height: ${({ fullSize }) => (fullSize ? "auto " : "700px")};
    margin-bottom: 20px;

    span {
        display: block;
    }

    img {
        width: ${({ fullSize }) => (fullSize ? "100%" : "auto")} !important;

        @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
            left: ${({ alignRight }) => (alignRight ? "auto" : "0px")} !important;
            right: ${({ alignRight }) => (alignRight ? "0px" : "auto")} !important;
        }
    }
`

const Link = styled(GatsbyLink)`
    display: none;
    z-index: 10;
    width: 32px;
    height: 32px;
    background: ${(props) => props.theme.colors.blue};
    justify-content: center;
    align-items: center;
`

const LinkWrapper = styled.div<Omit<IImageProps, "file">>`
    position: absolute;
    top: 20px;
    left: ${(props) => (props.alignRight ? "auto" : "20px")};
    right: ${(props) => (props.alignRight ? "20px" : "auto")};
    z-index: 10;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: flex-start;
    justify-content: ${(props) => (props.alignRight ? "flex-end" : "flex-start")};

    &:hover {
        a {
            display: flex;
        }
    }

    @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
        display: none;
    }
`

const OnMobile = styled.div`
    display: none;

    @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
        display: block;
    }
`

const OnDesktop = styled.div`
    display: none;

    @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
        display: block;
    }
`

export interface IImageProps {
    file: File
    alignRight?: boolean
    fullSize?: boolean
}

const ImageComponent = ({ file, alignRight, fullSize }: IImageProps) => {
    if (!file.childImageSharp || !file.childImageSharp.fluid || !file.fields || !file.fields.slug) {
        return null
    }

    return (
        <Box padding={["0px", "20px"]} style={{ position: "relative" }}>
            <LinkWrapper alignRight={alignRight}>
                <Link to={file.fields.slug}>
                    <IconZoom width="16" fill="white" />
                </Link>
            </LinkWrapper>
            <OnMobile>
                <GatsbyLink to={file.fields.slug}>
                    <Img Tag="span" fluid={file.childImageSharp.fluid} alignRight={alignRight} fullSize={fullSize} />
                </GatsbyLink>
            </OnMobile>
            <OnDesktop>
                <Img Tag="span" fluid={file.childImageSharp.fluid} alignRight={alignRight} fullSize={fullSize} />
            </OnDesktop>
        </Box>
    )
}

export const Image = React.memo(ImageComponent)
