import React from "react"
import { Box, Flex } from "rebass"

export interface IColumnsProps {
    wide?: boolean
    children: React.ReactNodeArray
}

const ColumnsComponent = ({ wide, ...props }: IColumnsProps) => {
    const columns = wide ? 5 : 2
    const children = props.children.flat().filter(Boolean)
    const cols = [...Array(columns).keys()].map((c) => children.filter((_, i) => i % columns === c)).filter((rows) => rows.length > 0)

    const columnSizes = ["100%"]
    if (wide) {
        columnSizes.push("33%", "20%")
    } else {
        columnSizes.push("50%")
    }

    return (
        <Flex flexWrap="wrap" justifyContent="center">
            {cols.map((rows, i) => (
                <Box key={i} flex={columnSizes} maxWidth={columnSizes} paddingRight={["0px", "20px"]}>
                    {rows}
                </Box>
            ))}
        </Flex>
    )
}

export const Columns = React.memo(ColumnsComponent)
